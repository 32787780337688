import type { ApplicationPermission } from 'types/graphql'

import { baserowColors } from '../HubDash/lib/baserow/baserowColors'

export const generateBasePrefix = (str: string) => {
  if (!str) return ''
  if (str.length < 2) return str.toUpperCase()
  return str.charAt(0).toUpperCase() + str.charAt(1).toLowerCase()
}

const darkerBaserowColors = baserowColors.filter((color) =>
  color.name.includes('darker'),
)

export const generatePseudoRandomDarkerColor = (baseId: number) => {
  // create a hex based off a baseId so its consistent for a base
  // yet still random enough to not be the same for each base

  // use darker colors since they look better with white text/icons
  const index = baseId ? baseId % darkerBaserowColors.length : 0
  const randomColor = darkerBaserowColors[index]
  return randomColor
}

export const isDarkColor = (colorName?: string): boolean =>
  Boolean(colorName?.includes('dark'))

// if my original base is called "My Base" then the duplicate base will be in pattern
// "My Base 1" or "My Base 2" etc.
export const baseDuplicatedPattern = (baseName: string) =>
  new RegExp(`^${baseName} \\d+$`)

export const baseHasPermission = (
  permission: ApplicationPermission,
  baseId: number,
) => permission.default || permission.exceptions.includes(baseId)
